<script lang="ts">
  export let href: string = '#';
</script>

<a {href} class="link">
  <slot />
</a>

<style>
  .link {
    color: #0a7cff;
    font-size: 15px;
    line-height: 15px;
    margin-left: var(--forgot-password-margin-left);
    margin-right: var(--forgot-password-margin-right);
    text-decoration: underline;
    font-family: Calibre, Helvetica Neue, Segoe UI, Helvetica, Arial,
      Lucida Grande, sans-serif;
  }
</style>
