import { addMessages, init, getLocaleFromNavigator } from 'svelte-i18n';
import en from './en';
import ar from './ar';
import fr from './fr';
import es from './es';
import de from './de';
addMessages('en', en);
addMessages('ar', ar);
addMessages('fr', fr);
addMessages('es', es);
addMessages('de', de);
init({
    fallbackLocale: 'en',
    initialLocale: getLocaleFromNavigator()
});
