<script lang="ts">
  export let placeholder: string;
  export let type: string;
  export let required: boolean;

  export let value = '';

  const handleInput = (event: Event) => {
    value = (event.target as HTMLInputElement).value;
  };
</script>

<input
  class="text-input"
  on:input={handleInput}
  {value}
  {type}
  {required}
  {placeholder}
/>

<style>
  .text-input {
    -webkit-font-smoothing: antialiased;
    margin: 0;
    line-height: 16px;
    background: rgba(0, 0, 0, 0.04);
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    color: black;
    display: block;
    font-family: Calibre, Helvetica Neue, Segoe UI, Helvetica, Arial,
      Lucida Grande, sans-serif;
    font-size: var(--form-control-font-size);
    height: 36px;
    margin-bottom: 12px;
    padding: 14px 16px 8px 16px;
    width: 100%;
  }

  .text-input::placeholder {
    color: #999999;
  }

  .text-input:focus {
    border: 1px solid #0084ff;
    border-radius: 10px;
    outline: none;
  }

  .text-input:focus::placeholder {
    color: #bec3c9;
  }
</style>
