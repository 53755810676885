export default {
    inputs: {
        email: 'رقم الهاتف المحمول أو البريد الإلكتروني',
        password: 'كلمة السر'
    },
    buttons: {
        login: 'تسجيل الدخول',
        forgottenPassword: 'هل نسيت كلمة السر؟'
    },
    messages: {
        loginFailed: 'فشل تسجيل الدخول، حاول مرة أخرى'
    }
};
