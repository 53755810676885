<script lang="ts">
  export let type: string;
</script>

<button class="button" {type}>
  <slot />
</button>

<style>
  .button {
    cursor: pointer;
    box-sizing: content-box;
    -webkit-font-smoothing: antialiased;
    justify-content: center;
    text-align: center;
    transition: 200ms cubic-bezier(0.08, 0.52, 0.52, 1) background-color,
      200ms cubic-bezier(0.08, 0.52, 0.52, 1) box-shadow,
      200ms cubic-bezier(0.08, 0.52, 0.52, 1) transform;
    border: 1px solid #0084ff;
    border-radius: 24px;
    color: #fff;
    display: block;
    font-family: Calibre, Helvetica Neue, Segoe UI, Helvetica, Arial,
      Lucida Grande, sans-serif;
    font-size: var(--form-control-font-size);
    height: 38px;
    padding: 5px 20px 0;
    width: auto;
    background-color: #0a7cff;
    border-color: #0a7cff;
    line-height: 17px;
    font-weight: 600;
    margin: 0;
  }

  .button:active {
    background-color: #0077e5;
    border: 1px solid transparent;
    opacity: 0.2;
  }

  .button:focus {
    border: 1px solid #0084ff;
    border-radius: 24px;
    box-shadow: 0 0 1px 2px rgba(88, 144, 255, 0.75),
      0 1px 1px rgba(0, 0, 0, 0.15);
    outline: none;
  }

  .button:hover {
    background-color: #4d4dff;
    border-color: #4d4dff;
  }
</style>
