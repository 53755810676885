export default {
    inputs: {
        email: 'Email address or phone number',
        password: 'Password'
    },
    buttons: {
        login: 'Log In',
        forgottenPassword: 'Forgotten your password?'
    },
    messages: {
        loginFailed: 'Log in failed, try again'
    }
};
