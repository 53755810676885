export default {
    inputs: {
        email: 'Numéro de mobile ou adresse e-mail',
        password: 'Mot de passe'
    },
    buttons: {
        login: 'Se connecter',
        forgottenPassword: 'Mot de passe oublié ?'
    },
    messages: {
        loginFailed: 'La connexion a échoué, réessayez'
    }
};
