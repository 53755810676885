export default {
    inputs: {
        email: 'Handynummer oder E-Mail-Adresse',
        password: 'Passwort'
    },
    buttons: {
        login: 'Anmelden',
        forgottenPassword: 'Passwort vergessen?'
    },
    messages: {
        loginFailed: 'Anmeldung fehlgeschlagen erneut versuchen'
    }
};
