export default {
    inputs: {
        email: 'Número de celular o correo electrónico',
        password: 'Contraseña'
    },
    buttons: {
        login: 'Iniciar sesión',
        forgottenPassword: '¿Olvidaste tu contraseña?'
    },
    messages: {
        loginFailed: 'Ingreso fallido intente nuevamente'
    }
};
